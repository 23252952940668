// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.general-content {
  padding: 0 40px 100px 40px;
  .top-title-button {
    display: flex;
    .top-title {
      width: 50%;
      font-size: 24px;
      color: #000000;
      padding: 5px 0 35px 5px;
      span {
        color: #0073bb;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .buttons {
      flex: 1;
      text-align: right;
      button {
        margin-left: 15px;
      }
    }
  }

  .log-stream-table {
    width: 100%;
    a {
      color: #0073bb;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #0073bb;
      }
    }
    th,
    td {
      text-align: left;
      padding: 8px 20px;
      border-bottom: 1px solid #eaeded;
    }
    // tr:nth-child(odd) > td,
    // .tr:nth-child(odd) > th {
    //   background-color: #fafafa; // Choose your own color here
    // }
    th {
      background-color: #fafafa;
      border-top: 1px solid #eaeded;
      border-bottom: 1px solid #eaeded;
    }
  }

  .action-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .filter-input {
      position: relative;
      .search-icon {
        position: absolute;
        margin: 4px 0 0 4px;
        color: #444;
      }
      .text-input {
        width: 565px;
        border: 1px solid #aab7b8;
        background-color: #fff;
        height: 32px;
        line-height: 32px;
        padding: 0 5px 0px 30px;
        margin-right: 10px;
      }
    }
  }

  .general-info {
    font-size: 14px;
    &.tab-padding {
      margin-top: 15px;
    }
    &.box-shadow {
      background-color: #ffffff;
      box-shadow: 0px 2px 2px #ccc;
    }
    .header {
      background-color: #fafafa;
      border-bottom: 1px solid #eaeded;
      padding: 15px 20px 15px 20px;
      .header-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .big-title {
          color: #000000;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .title {
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      background-color: #fafafa;
      border-bottom: 1px solid #eaeded;
      padding: 15px 0 15px 20px;
    }
    .progress-bar {
      display: flex;
      .bar {
        padding: 5px 0 0 0;
        flex: 1;
      }
      .number {
        color: #687078;
        width: 40px;
        text-align: right;
      }
    }
    .engine-title {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 10px;
      padding-left: 20px;
      border-bottom: 1px solid #eee;
      margin-left: 20px;
      margin-right: 20px;
      .option-title-icon {
        position: absolute;
        margin-left: -20px;
        margin-top: -3px;
        cursor: pointer;
      }
    }
    &-content {
      display: flex;
      padding: 25px 0;

      .split-item {
        flex: 1;
        padding: 3px 20px;
        border-left: 1px solid #eaeded;
        color: #000;
        .sub-name {
          color: #545b64;
          padding: 2px 0;
        }
        .image-list {
          width: 100%;
          border: 1px solid #aaa;
          background: #fff;
          padding: 8px;
        }
        .open-icon {
          position: absolute;
          margin: 0px 0 0 3px;
        }
      }
    }
  }
  .tab-content {
    margin: 40px 0 0 0;
  }
}

.log-table {
  margin: 10px 0px 10px 40px;
  &.box-shadow {
    background-color: #ffffff;
    box-shadow: 0px 2px 2px #ccc;
  }
  .header {
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    padding: 15px 20px 15px 20px;
    .header-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .big-title {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    padding: 15px 0 15px 20px;
  }
  table {
    width: 100%;
    a {
      color: #0073bb;
      cursor: pointer;
      &:hover {
        color: #0073bb;
        text-decoration: underline;
      }
    }
    th,
    td {
      text-align: left;
      padding: 8px 20px;
      border-bottom: 1px solid #eaeded;
    }
    tr:nth-child(even) > td,
    .tr:nth-child(even) > th {
      background: rgba(0, 0, 0, 0.05); // Choose your own color here
    }
    th {
      background-color: #fafafa;
      border-top: 1px solid #eaeded;
      border-bottom: 1px solid #eaeded;
    }
  }
}
