// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.drh-top-bar {
  width: 100%;
  height: 40px;
  background-color: #232f3e;
  padding: 0 0 0 50px;
  color: #fff;
  font-size: 14px;
  .logo {
    padding: 8px 0 0 0;
    span {
      position: absolute;
      margin: 3px 0 0 10px;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .options {
    position: absolute;
    right: 50px;
    top: 0;
    height: 40px;
    overflow: hidden;
    .user-item {
      // cursor: pointer;
      display: inline-block;
      padding: 10px 0 0 30px;
      margin-left: 15px;
      img {
        position: absolute;
        margin: 0px 0 0 -30px;
      }
    }
    .logout-item {
      display: inline-block;
      padding: 10px 0 0 0px;
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
