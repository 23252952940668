// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// @font-face {font-family: "Amazon Ember"; src: url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.eot"); src: url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.woff") format("woff"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.svg#Amazon Ember") format("svg"); }
// $font-family: "Amazon Ember", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
// 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
// sans-serif;
$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;

body,
input,
textarea,
button {
  font-size: 14px;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f3;
}

.gray {
  color: #888;
}

a,
a:link,
a:active,
a:visited {
  &.a-link {
    color: #0073bb;
    text-decoration: none;
  }
}
a:hover {
  &.a-link {
    color: #0073bb;
    text-decoration: underline;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.no-data {
  padding: 15px;
  color: #888;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.desc-link {
  padding-right: 25px;
  .icon {
    position: absolute;
    width: 18px;
    margin: -2px 0 0 2px;
  }
}

.sign-in-form-footer {
  display: none;
}

.sign-in-form-footer span {
  display: none !important;
}

.MuiTypography-body1,
.MuiButton-root,
.MuiTab-root {
  font-family: $font-family !important;
}

* {
  box-sizing: border-box;
}

.pr {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hand-point {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}

.w-100p {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.over-mask {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.2);
  .card {
    width: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -100px 0 0 -150px;
    .relogin-botton {
      margin: 20px;
    }
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.login-wrap {
  width: 460px;
  margin: 0 auto;
  .text-title {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    color: #ff9900;
    text-shadow: 1px 1px #ddd;
    // color: #fff;
    padding: 5px 0 10px 0;
  }
}

.sign-out,
.logout-btn-style {
  display: inline-block;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.loading-style {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 40px auto;
}

.background-white {
  background: "#fff";
}

.margin-left-10 {
  margin-left: 10px !important;
}

.padding-20 {
  padding: 20px;
}

.padding-15 {
  padding: 15px;
  button {
    margin-left: 10px;
  }
}

.plr-20 {
  padding: 0 20px;
}

.padding-left-40 {
  padding-left: 0px;
}

.padding-right-40 {
  padding-right: 40px;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.font14px {
  font-size: 14px !important;
}

.hidden {
  display: none !important;
}

.info-span {
  color: #0073bb !important;
  font-weight: bold !important;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
}

.credential-empty {
  min-height: 30px !important;
}

.task-list-status {
  .task-info {
    .status {
      &.error {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.task-info {
  .status {
    padding-left: 30px;
    margin-left: -10px;
    &.error {
      color: #d13212;
    }
    &.gray {
      color: #888;
    }
    &.success {
      color: green;
    }
    .icon {
      position: absolute;
      margin: -1px 0 0 -22px;
    }
    img {
      position: absolute;
      margin: 0 0 0 -20px;
    }
  }
  .status-pop {
    position: absolute;
    z-index: 99;
    margin-top: -18px;
    margin-left: 55px;
    border: 1px solid #999;
    background-color: #fff;
    width: 340px;
    padding: 10px;
    word-break: break-word !important;
    white-space: pre-wrap;
    overflow: auto;
    .error-message {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6; /* number of lines to show */
      line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .view-more {
      color: #666;
      padding: 4px 0;
      a {
        text-decoration: underline !important;
        i {
          position: absolute;
        }
      }
    }
  }
}

.drh-page {
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  .left {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    box-shadow: 0px 2px 2px #ccc;
    width: 40px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    &.opened {
      width: 280px;
      cursor: default;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
  .right {
    position: relative;
    z-index: 4;
    flex: 1;
    overflow: auto;
    padding-bottom: 30px;
    .bottom {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 999;
      left: 0;
      right: 0;
      height: 30px;
      background-color: #232f3e;
    }
  }
}

.drh-input-radio-item {
  margin-right: 20px;
  input {
    margin-right: 3px;
  }
}

.monitor-menu {
  display: flex;
  justify-content: space-between;
  .monitor-tabs {
    display: flex;
    box-shadow: 0 1px 1px rgba(0, 28, 36, 0.3);
    padding: 0;
    margin: 0;
    height: 42px;
    .tab-item {
      background-color: #fafafa;
      border-top: 4px solid #fafafa;
      padding: 8px 12px;
      border-right: 1px solid #eaeded;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #fff;
      }
      &.item-active {
        font-weight: bold;
        background-color: #fff;
        border-top: 4px solid #ec7211;
      }
    }
  }
}

.modal-time-range {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 999;
}

.monitor-filters {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-time-range,
.monitor-filters {
  .metrics-time-filter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .specify-time {
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #aab7b8;
    background-color: #fff;
    position: relative;
    .item {
      background-color: #fff;
      display: inline-block;
      padding: 8px 20px;
      cursor: pointer;
      &:active {
        background-color: #eee;
      }
      &.item-active {
        font-weight: bold;
        color: #0073bb;
      }
      &.custom {
        position: absolute;
        background-color: #fff;
        border: 1px solid #aab7b8;
        margin-top: 36px;
        padding: 2px 0;
        right: 0;
        .time-range-picker {
          position: relative;
          padding: 0px;
          .react-minimal-datetime-range__range-input-wrapper {
            border-radius: 0;
            border: 0;
          }
          .react-minimal-datetime-range {
            width: 540px;
            right: 0;
            margin-top: 4px;
          }
          .react-minimal-datetime-date-piker {
            text-align: right;
          }

          // .react-minimal-datetime-range-calendar__table-cel.no-border,
          // .react-minimal-datetime-range-calendar__table-cel,
          .react-minimal-datetime-range-calendar__table-cel.react-minimal-datetime-range-calendar__date-item:not(
              .disabled
            ) {
            padding: 10px;
          }

          .react-minimal-datetime-range__button {
            border-radius: 0px;
            cursor: pointer;
            display: inline-block;
            font-size: 12px;
            margin-right: 10px;
            padding: 8px 15px;
            color: #ec7211;
          }
          .react-minimal-datetime-range__button--confirm {
            background: #ec7211;
            border: 1px solid #ec7211;
            color: #fff;
          }
        }
      }
    }
  }
}

.monitor-chart-list {
  margin-top: 37px;
  display: flex;
  flex-wrap: wrap;
  .monitor-chart {
    background-color: #fff;
    width: 50%;
    margin-top: 3px;
    padding: 10px 10px 20px;
    box-shadow: 0 2px 2px #ccc;
    .zoom {
      position: absolute;
      cursor: pointer;
      right: 5px;
      top: 5px;
      z-index: 10;
    }
  }
}

.chart-mask {
  position: absolute;
  z-index: 99;
  background-color: rgba($color: #fff, $alpha: 0.5);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-more-wrap {
  text-align: center;
  .load-more {
    cursor: pointer;
    color: #0073bb;
  }
}

.MuiButton-root {
  text-transform: none !important;
}

/**material ui*/
.PrivateSwitchBase-root-1 {
  padding: 0 !important;
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiAutocomplete-listbox {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

$loading-color: #555;
.gsui-loading {
  position: relative;
  color: $loading-color;
  padding-left: 20px;
  .icon {
    position: absolute;
    color: $loading-color;
    width: 15px;
    margin: 3px 0 0 -20px;
  }
}

.gsui-alert-wrap {
  border: 1px solid #0073bb;
  background-color: #f1faff;
  padding: 12px 20px;
  display: flex;
  align-items: flex-start;
  &.margin {
    margin: 0 0 15px 0;
  }
  &.error {
    border: 1px solid #aab7b8;
    background-color: #ffffff;
  }
  &.warning {
    border: 1px solid #d13212;
    background-color: #fdf3f1;
  }
  .red {
    color: #d13212;
  }
  .icon {
    color: #0073bb;
    width: 30px;
    .error-text {
      color: #d13212;
    }
  }
  .text {
    padding-left: 5px;
    flex: 1;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    &-content {
      padding-top: 4px;
      font-size: 14px;
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
}

.dth-login-title {
  color: #444;
  font-size: 24px;
  padding: 0 0 5px 0;
  text-align: center;
}

.dth-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.app-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.modal-chart-container {
  min-height: 430px;
}
