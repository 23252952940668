// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.step-list {
  padding: 0px 0 20px 0;
  border-bottom: 1px solid #d5dbdb;
  margin-bottom: 15px;
  // cursor: pointer;
  .step-title {
    font-size: 14px;
    color: #686f77;
    padding: 0 0 5px 0;
  }
  .step-desc {
    color: #0073bb;
    padding-bottom: 5px;
    &.active {
      color: #000000;
      font-weight: bold;
    }
  }
}
